import React, { useContext } from 'react';

import { languageOptions } from '../languages';
import { LanguageContext } from '../containers/Language';

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = e => userLanguageChange(e.target.value);

  return (
    
            <div className='navbar-item'>
              <div className="select is-primary" >
                  <select
                  onChange={handleLanguageChange}
                  value={userLanguage}
                  >
                  {Object.entries(languageOptions).map(([id, name]) => (
                      <option key={id} value={id}>{name}</option>
                  ))}
                  </select>
              </div>
            </div>
        
  );
};